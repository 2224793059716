import React, { useState } from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import languageEn from "../../constants/languages/en/translations.json";
import languageEs from "../../constants/languages/es/translations.json";
import languagePb from "../../constants/languages/pb/translations.json";
import notice from "../../assets/files/privacyNotice.pdf";
import { MotifButton } from "@ey-xd/motif-react";
import { RightTick } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import "./Home.scss"; // Import the CSS file here

const Disclaimer = ({ onAgree }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const renderContentForTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return languageEn.DisclaimerContentEN;
      case 1:
        return languageEs.DisclaimerContentES;
      case 2:
        return languagePb.DisclaimerContentPB;
      default:
        return "";
    }
  };
  const clickableLinkText = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return languageEn.PrivacyNoticeClickableEN;
      case 1:
        return languageEs.PrivacyNoticeClickableES;
      case 2:
        return languagePb.PrivacyNoticeClickablePB;
      default:
        return languageEn.PrivacyNoticeClickableEN;
    }
  };
  const privacyNoticeText = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return languageEn.PrivacyNoticeNonClickableEN;
      case 1:
        return languageEs.PrivacyNoticeNonClickableES;
      case 2:
        return languagePb.PrivacyNoticeNonClickablePB;
      default:
        return languageEn.PrivacyNoticeNonClickableEN;
    }
  };
  const acknowledgeButtonText = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return languageEn.AcknowledgeButtonTextEN;
      case 1:
        return languageEs.AcknowledgeButtonTextES;
      case 2:
        return languagePb.AcknowledgeButtonTextPB;
      default:
        return languageEn.AcknowledgeButtonTextEN;
    }
  };
  const disclaimerText = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return languageEn.DisclaimerHeadingEN;
      case 1:
        return languageEs.DisclaimerHeadingES;
      case 2:
        return languagePb.DisclaimerHeadingPB;
      default:
        return languageEn.DisclaimerHeadingEN;
    }
  };

  return (
    <div data-testid="disclaimer" className="modal">
      <div className="modalContent">
        <h2>
          <MotifTabNavigation defaultActiveKey={activeTab} variant="alt">
            <MotifTabControl
              onClick={() => handleTabChange(0)}
              tabkey={0}
              className={`tab-highlight ${
                activeTab === 0 ? "tab-highlight-active" : ""
              }`}
            >
              {languageEn.DisclaimerTabEN}
            </MotifTabControl>
            <span className="tab-separator">|</span>
            <MotifTabControl
              onClick={() => handleTabChange(1)}
              tabKey={1}
              className={`tab-highlight ${
                activeTab === 1 ? "tab-highlight-active" : ""
              }`}
            >
              {languageEs.DisclaimerTabES}
            </MotifTabControl>
            <span className="tab-separator">|</span>
            <MotifTabControl
              onClick={() => handleTabChange(2)}
              tabKey={2}
              className={`tab-highlight ${
                activeTab === 2 ? "tab-highlight-active" : ""
              }`}
            >
              {languagePb.DisclaimerTabPB}
            </MotifTabControl>
          </MotifTabNavigation>
        </h2>
        <div className="disclaimer-container">
          <h3 className="disclaimer">{disclaimerText(activeTab)}</h3>
          <p>{renderContentForTab(activeTab)}</p>
          <p>
            <a
              href={notice}
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: "#0F69AE" }}
            >
              {clickableLinkText(activeTab)}
            </a>
            <strong>{privacyNoticeText(activeTab)} </strong>
          </p>
        </div>
        <div>
          <MotifButton
            className="acknowledgeButton"
            data-testid="acknowledge-button"
            onClick={onAgree}
          >
            {acknowledgeButtonText(activeTab)}
            &nbsp;
            <RightTick />
          </MotifButton>
        </div>
      </div>
    </div>
  );
};
Disclaimer.propTypes = {
  onAgree: PropTypes.func.isRequired,
};

export default Disclaimer;
