import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setUser } from "../../features/slices/HomeSlice";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import notice from "../../assets/files/privacyNotice.pdf";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MotifButton,
  MotifAvatar,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import { PrivacyNote, HelpDesk } from "../../constants/icons/Icons";
import EYHeaderLogo from "../../assets/images/HeaderLogo.png";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);
  const name = userState.user?.name;
  const photo = userState.photo;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  useEffect(() => {
    if (userState.user) {
      const storedUserData = localStorage.getItem("user");
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        dispatch(setUser(userData));
      }
      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      } else {
        const preferredLanguage = "en";
        i18n.changeLanguage(preferredLanguage);
        localStorage.setItem("language", preferredLanguage);
      }
    }
  }, [dispatch, i18n, userState.user]);

  const handleSignOut = async () => {
    try {
      dispatch(logoutUser());
      localStorage.removeItem("user");
      localStorage.removeItem("language");
      sessionStorage.clear();
      if (window.msalInstance) {
        console.log("MSAL instance found, logging out");
        await window.msalInstance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error during sign-out", error);
    }
  };
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  };

  return (
    <header className="header-container">
      <div className="logo-title">
        <a href="/" aria-label="Home">
          <img src={EYHeaderLogo} alt="Logo" width="40" height="40" />
        </a>
        <h1 className="brand-title">{t("Title")}</h1>
      </div>
      <nav className="nav-items">
        <ul>
          <li>
            <MotifButton
              className="motif-body"
              onClick={() => window.open(notice, "_blank")}
              data-testid="privacy-note-button"
            >
              <PrivacyNote />
              {t("PrivacyNote")}
            </MotifButton>
          </li>
          <li>
            <MotifButton className="motif-body" data-testid="help-desk-button">
              <HelpDesk />
              {t("HelpDesk")}
            </MotifButton>
          </li>
        </ul>
      </nav>
      <div className="right-menu">
        <button
          className="burger-menu"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          data-testid="menu-icon"
          aria-label="Toggle mobile menu"
        >
          <MenuIcon size={30} />
        </button>
        {userState.user && (
          <>
            <MotifAvatar
              className="avatar-portal"
              description={name}
              size="medium"
              userName={name}
              src={photo ? `data:image/png;base64,${photo}` : undefined}
              onError={(e) => (e.target.src = undefined)}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              data-testid="user-avatar"
            />
            {dropdownVisible && (
              <div className="dropdown-menu show">
                <ul>
                  <li>
                    <MotifSelect
                      ariaLabelledBy="select-label"
                      onChange={(e) => handleLanguageChange(e)}
                      placeholder={t("Language")}
                      value={i18n.language}
                      visibleOptions={3}
                    >
                      <MotifOption value="en">EN</MotifOption>
                      <MotifOption value="es">ES</MotifOption>
                    </MotifSelect>
                  </li>
                  <li>
                    <MotifButton
                      className="motif-body"
                      onClick={handleSignOut}
                      data-testid="sign-out-button"
                    >
                      {t("SignOut")}
                    </MotifButton>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </div>
      {mobileMenuVisible && (
        <div className="mobile-menu show">
          <ul>
            <li>
              <MotifButton
                className="motif-body"
                onClick={() => window.open(notice, "_blank")}
                data-testid="mobile-privacy-note-button"
              >
                <PrivacyNote />
                {t("PrivacyNote")}
              </MotifButton>
            </li>
            <li>
              <MotifButton
                className="motif-body"
                data-testid="mobile-help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </li>
            {userState.user && (
              <li>
                <MotifButton
                  className="motif-body"
                  onClick={handleSignOut}
                  data-testid="mobile-sign-out-button"
                >
                  {t("SignOut")}
                </MotifButton>
              </li>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
